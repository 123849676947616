import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import RedirectComponent from "./components/redirect.component";
class App extends React.Component {
  render() {
    return (
      <Router>
        <main>
          <Switch>
            <Route path="/:urlID" component={RedirectComponent} exact></Route>
          </Switch>
        </main>
      </Router>
    );
  }
}

export default App;
