import React, { Component } from "react";

import URLService from "../services/url.shortener";

export default class RedirectComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlID: props.match.params.urlID,
      fail: false,
    };
  }

  componentDidMount() {
    if (this.state.urlID !== undefined) {
      URLService.getRedirectURL(this.state.urlID).then(
        (response) => {
          window.location = response.data;
        },
        (error) => {
          this.setState({ fail: true });
        }
      );
    } else {
      window.location = "https://sidusgames.eu";
    }

    if(this.state.urlID == null) {
      window.location = "https://sidusgames.eu";
    }

    if(this.state.urlID === undefined) {
      window.location = "https://sidusgames.eu";
    }

    if(this.state.urlID === "") {
      window.location = "https://sidusgames.eu";
    }
  }

  render() {
    if (!this.state.fail) {
      return (
        <>
          <p>Du wirst weitergeleitet...</p>
        </>
      );
    } else {
      return (
        <>
          <p>
            Es gibt keine Weiterleitung mit: <strong>{this.state.urlID}</strong>
          </p>
        </>
      );
    }
  }
}
