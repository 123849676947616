import axios from "axios";

// const API_URL = "https://backend.sidusgames.eu/api/v1/urlshortener/";
const API_URL = "https://backend.sidusgames.eu/api/v1/urlshortener/";

// const API_URL = "http://localhost:1234/api/v1/urlshortener/";

class URLService {
  getRedirectURL(id) {
    return axios.post(API_URL + "getRedirectUrl", {
      urlID: id,
    });
  }
}

export default new URLService();
